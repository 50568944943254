<template>
  <b-modal v-model="isOpen" :show="isOpen" @hide="close">
    <template #modal-header="{ close }">
        <h5>{{ modalTitle }} {{ $t("document") }}</h5>
      <!-- <h5>{{ $t("NEW") }} </h5> -->
      <b-button size="sm" @click="close()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>
    <form>
    
      <div class="center">
        <b-form-group label="Nom ficher" label-for="lib">
          <b-form-input id="lib" v-model="document.lib" required></b-form-input>
        </b-form-group>
        <b-form-group label="document" label-for="documentfile">
          <b-form-file
            v-model="document.file"
            id="documentfile"
            placeholder=""
            drop-placeholder=""
            class="file"
          ></b-form-file>
        </b-form-group>
        <b-form-group label="Categorie" label-for="Categorie">
          <multiselect
            v-model="document.categorie"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllCategorie"
            label="name"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group label-for="Description" label="Description">
          <b-form-textarea
            id="desc"
            v-model="document.description"
            placeholder="Description..."
            rows="3"
            max-rows="6"
            required
          ></b-form-textarea>
        </b-form-group>
      </div>
    </form>
    <template #modal-footer>
      <div class="actionModel">
        <b-button variant="success" @click="validate_doc">
          <div class="block-spinner">
            {{ $t("CONFIRM") }}
            <div v-if="getclientLoading" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { domains } from "@/environment";
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
  isOpen: {
    type: Boolean,
    required: true,
  },
  document: {
    type: Object,
    default: () => ({
      lib: null,
      description: null,
      categorie: null,
      file: null,
    }),
  },
},

  data() {
    return {
      downloadUrl: domains.download,
      filetoupdate: {
        lib: null,
        description: null,
        categorie: null,
        client_id: null,
        file: null,
      },
    
      errorD: null,
      documents: null,
    };
  },
  computed: {
    ...mapGetters(["getAllCategorie", "getclientLoading"]),
    modalTitle() {
      return this.document.lib==""
        ? this.$t("NEW")
        : this.$t("EDIT");
    },
},
  mounted() {
  
    if (this.isSuperAdmin || this.isentrepriseAdmin)
      this.allCategorie({ page: 1, per_page: 1000 });
  },
  methods: {
    ...mapActions([
      "add_to_datafile",
      "delete_from_datafile",
      "update_datafile",
      "allCategorie",
    ]),
    close() {
      this.$emit("close");
    },

    resetModal() {
      this.documents = null;

      this.document = {
        lib: null,
        description: null,
        categorie: null,
        file: null,
      };

      this.errorD = null;
      this.$router.push("/detail/client/" + this.document.client_id);
    },
    validate_doc() {
  const client_id = this.$route.params.id;
  var bodyFormData = new FormData();

  bodyFormData.append("lib", this.document.lib);
  bodyFormData.append("category_file_id", this.document.categorie.id);
  bodyFormData.append("description", this.document.description);
  bodyFormData.append("client_id", client_id);
  
  if (this.document.file) {
    bodyFormData.append("file", this.document.file);
  } else {
    console.error("No file provided");
  }
  this.$emit("confirm", bodyFormData);
},

  },
};
</script>
<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
</style>
